import React from 'react';
import { AsyncStore } from '../stores/AsyncStore';

import { AuthenticationStore } from '../stores/AuthenticationStore';
import { ErrorStore } from '../stores/ErrorStore';
import { StudyCriteriaStore } from '../stores/StudyCriteriaStore';
import { StudyLegendStore } from '../stores/StudyLegendStore';
import { StudyRemindersStore } from '../stores/StudyRemindersStore';
import { StudyStore } from '../stores/StudyStore';
import { StudyVisitChecklistStore } from '../stores/StudyVisitChecklistStore';

const errorStore = new ErrorStore();
const asyncStore = new AsyncStore(errorStore);
const authenticationStore = new AuthenticationStore();

const studyStore = new StudyStore();

const studyCriteriaStore = new StudyCriteriaStore(studyStore);
const studyVisitChecklistStore = new StudyVisitChecklistStore(studyStore);
const studyRemindersStore = new StudyRemindersStore(studyStore);
const studyLegendStore = new StudyLegendStore(studyStore);

const storesContext = React.createContext({
  errorStore,
  asyncStore,
  authenticationStore,
  studyStore,
  studyCriteriaStore,
  studyVisitChecklistStore,
  studyRemindersStore,
  studyLegendStore
});

export const useStores = () => {
  return React.useContext(storesContext);
}