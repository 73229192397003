import * as Icons from "react-icons/io5";
import React, { useState } from 'react';

import { Form, InputGroup, Modal } from "react-bootstrap";
import { Ionicons } from "../../utility/Ionicons";
import { validIcons } from '../../config/validIcons';

type Props = {
  show: boolean;
  onSelect: (iconName: string) => void;
  onHide: () => void;
};

const maxColumns = 8;
const iconKeys = Object.keys(Icons);
const availableIcons = iconKeys.filter((key) => {
  const iconName = Ionicons.convertComponentNameToIconName(key);

  return validIcons.includes(iconName)
});

const IoniconModal: React.FC<Props> = ({ show, onSelect, onHide }) => {

  const [ iconComponentNames, setIconComponentNames ] = useState(availableIcons);

  const handleOnClick = (iconKey: string) => {
    onSelect(Ionicons.convertComponentNameToIconName(iconKey));
  };

  const handleOnSearchChange = (value: string) => {
    const iconComponentNames = availableIcons.filter((iconName) => iconName.toLowerCase().includes(value.toLowerCase()));

    setIconComponentNames(iconComponentNames);
  };

  const renderIconColumn = (iconComponentName: string) => {
    const Ionicon = (Icons as any)[iconComponentName];

    return (
      <td key={iconComponentName} className="selectable-icon" onClick={() => handleOnClick(iconComponentName)}>
        <Ionicon />
      </td>
    );
  };

  const renderIconRows = () => {
    const maxRows = iconComponentNames.length % maxColumns === 0 ?
      iconComponentNames.length / maxColumns : iconComponentNames.length / maxColumns + 1;

    const iconRows = [];

    for (let row = 0; row < maxRows; row++) {
      const startColumn = row * maxColumns;

      iconRows.push(<tr key={`ionicon-row-${row}`}>
        {
          iconComponentNames.slice(startColumn, startColumn + maxColumns).map((iconComponentName) => {
            return renderIconColumn(iconComponentName);
          })
        }
      </tr>);
    }

    return iconRows;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Icons
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <InputGroup.Text>
            <Icons.IoSearch />
          </InputGroup.Text>
          <Form.Control onChange={(e) => handleOnSearchChange(e.target.value)} />
        </InputGroup>
        
        <div className="icon-table-container">
          <table>
            <tbody>
              {renderIconRows()}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { IoniconModal };
