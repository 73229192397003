import React from 'react';

import { observer } from 'mobx-react-lite';
import { Card, ListGroup, Modal } from 'react-bootstrap';
import { useStores } from '../../hooks/useStores';
import { StudyVisitListGroupItem } from '../common/StudyVisitListGroupItem.component';
import { StudyVisit } from '../../models/StudyVisit';
import { useStudy } from '../../hooks/useStudy';

type Props = {
  show: boolean;
  onHide: () => void;
  onSelectVisit: (studyVisit: StudyVisit) => void;
};

const SelectVisitModal: React.FC<Props> = observer(({ show, onHide, onSelectVisit }) => {
  const { asyncStore, studyStore, studyVisitChecklistStore } = useStores();
  const { saveStudyConfig } = useStudy();

  const screenings = studyVisitChecklistStore.studyScreeningVisits;
  const normalVisits = studyVisitChecklistStore.studyNormalVisits;
  const offTreatmentVisits = studyVisitChecklistStore.studyOffTreatmentVisits;

  const handleSelectVisit = (studyVisit: StudyVisit) => {
    onSelectVisit(studyVisit);
  };

  const renderVisitListCard = (key: string, title: string, studyVisits: StudyVisit[]) => {
    return (
      <Card className="margin-bottom">
        <Card.Header>{title}</Card.Header>
        <ListGroup as="ol" variant="flush">
          {
            studyVisits.map((visit: StudyVisit, index: number) => {
              return (
                <StudyVisitListGroupItem key={`${key}-${index}`} visit={visit} onSelectVisit={handleSelectVisit} />
              )
            })
          }
        </ListGroup>
      </Card>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Select Visit
      </Modal.Header>
      <Modal.Body>
        {renderVisitListCard('screening-visit', 'Screenings', screenings)}
        {renderVisitListCard('normal-visit', 'Visits', normalVisits)}
        {renderVisitListCard('off-treatment-visit', 'Off Treatment Visits', offTreatmentVisits)}
      </Modal.Body>
    </Modal>
  );
});

export { SelectVisitModal };
