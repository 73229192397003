
import React from 'react';

import { observer } from 'mobx-react-lite';
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useStores } from '../../hooks/useStores';
import { useStudy } from '../../hooks/useStudy';
import { IoCreateOutline } from 'react-icons/io5';
import { StudyConfigDetail } from '../../models/StudyConfigDetail';

type Props = {
  show: boolean;
  studyConfigDetail: StudyConfigDetail;
  onHide: () => void;
};

const StudyProtocolDetail: React.FC<Props> = observer(({ show, studyConfigDetail, onHide }) => {
  const { studyStore, asyncStore } = useStores();
  const { createStudyConfig, loadStudyProtocols } = useStudy();
  const newStudyConfig = studyConfigDetail?.isNew();
  const studyName = studyConfigDetail?.studyName;

  const handleSubmit = () => {
    asyncStore.showLoading(async () => {
      if (newStudyConfig) {
        await createStudyConfig(studyConfigDetail.toStudyConfig());

        const result = await loadStudyProtocols(studyConfigDetail.studyName);
        studyStore.initializeAllStudyProtocols(result.studyProtocols);
        onHide();
      } else {
        studyStore.selectStudyConfig(studyConfigDetail.studyConfig);
        onHide();
      }
    });
  };

  const renderDate = (date: Date | undefined) => {
    if (!date) {
      return null;
    }

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const renderDetail = () => {
    return (
      <>
        <hr/>

        <Row>
          <Col className="form-label">
            Protocol Version
          </Col>
          <Col className="study-protocol-detail">
            {studyConfigDetail.protocolVersionLabel}
          </Col>
        </Row>

        <Row>
          <Col className="form-label">
            Protocol Code
          </Col>
          <Col className="study-protocol-detail">
            {studyConfigDetail.protocolCode}
          </Col>
        </Row>

        <Row>
          <Col className="form-label">
            Create Date
          </Col>
          <Col className="study-protocol-detail">
            {renderDate(studyConfigDetail.createDate)}
          </Col>
        </Row>

        <Row>
          <Col className="form-label">
            Created By
          </Col>
          <Col className="study-protocol-detail">
            {studyConfigDetail.createUser}
          </Col>
        </Row>

        <Row>
          <Col className="form-label">
            Update Date
          </Col>
          <Col className="study-protocol-detail">
            {renderDate(studyConfigDetail.updateDate)}
          </Col>
        </Row>

        <Row>
          <Col className="form-label">
            Updated By
          </Col>
          <Col className="study-protocol-detail">
            {studyConfigDetail.updateUser}
          </Col>
        </Row>

        <hr/>
        
        {
          !studyConfigDetail.isSameProtocol(studyStore.selectedStudyConfig) ?
            <div className="d-grid gap-2 margin-top">
              <Button variant="outline-primary" onClick={handleSubmit}>
                <IoCreateOutline style={{ fontSize: 24 }} /> Edit Protocol
              </Button>
            </div> : null
        }
      </>
    );
  };

  const renderNew = () => {
    return (
      <>
        <Form.Group className="margin-top">
          <Form.Label>
            Copy Protocol
          </Form.Label>
          <Form.Select>
            {studyStore.allStudyProtocols.map((studyProtocol) => {
              return (
                <option key={`study-protocol-option-${studyProtocol.studyId}`}>
                  {studyProtocol.protocolVersionLabel}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="margin-top">
          <Form.Label>
            Protocol Version
          </Form.Label>
          <Form.Control 
            placeholder="VX.X - DDMMMYY"
            value={studyConfigDetail?.protocolVersionLabel}
            onChange={(e) => studyConfigDetail.setProtocolVersionLabel(e.target.value)}
          />
        </Form.Group>
      
        <Form.Group className="margin-top">
          <Form.Label>
            Protocol Code
          </Form.Label>
          <Form.Control 
            placeholder="XXX-X-XXXX"
            value={studyConfigDetail?.protocolCode}
            onChange={(e) => studyConfigDetail.setProtocolCode(e.target.value)}
          />
        </Form.Group>


        <div className="d-grid gap-2 margin-top">
          <Button variant="primary" onClick={handleSubmit} disabled={!studyConfigDetail.isComplete()}>
            <IoCreateOutline style={{ fontSize: 24 }} /> Create Protocol
          </Button>
        </div> 
      </>
    );
  };

  const renderStudyConfigDetail = () => {
    return studyConfigDetail.isNew() ? renderNew() : renderDetail();
  }

  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header style={{ backgroundColor: '#EFEFEF'}} closeButton>
        <h5>{' '}</h5>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <img src={`/${studyName?.toLowerCase()}.jpg`} className="study-protocol-logo" />

        {studyConfigDetail ? renderStudyConfigDetail() : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
});

export { StudyProtocolDetail };