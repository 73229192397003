import React from 'react';

import { Modal, Table } from "react-bootstrap";
import { StudyLegendConfig } from '../../core/StudyConfig';
import { StudyLegend } from '../../models/StudyLegend';

type Props = {
  legend: StudyLegendConfig;
  show: boolean;
  onSelect: (alpha: string) => void;
  onHide: () => void;
};

// TODO move styles out
const LegendModal: React.FC<Props> = ({ legend, show, onSelect, onHide }) => {
  const footnoteKeys = StudyLegend.footnoteKeys();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Legend
      </Modal.Header>
      <Modal.Body>
        <Table hover className="full-width">
          <tbody>
            {
              legend.orderedList.map((legendItem, index) => {
                return (
                  <tr key={`legend-item-${index}`} onClick={() => onSelect(footnoteKeys[index])} className="clickable">
                    <td className="legend-modal-key">
                      {`${footnoteKeys[index]}.`}
                    </td>

                    <td className="legend-modal-footnote">
                      {legendItem}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export { LegendModal };
