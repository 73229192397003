export class Ionicons {
  static convertComponentNameToIconName(componentName: string) {
    const dashedIconName = componentName.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());

    return dashedIconName.slice(4, dashedIconName.length);
  };

  static convertIconNameToComponentName(iconName: string) {
    let convertedIconName = iconName.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    convertedIconName = convertedIconName.charAt(0).toUpperCase() + convertedIconName.slice(1);

    return 'Io' + convertedIconName;
  };

  static convertIconNameToAlternateComponentName(iconName: string) {
    let convertedIconName = iconName.replace('ios-', '');
    
    convertedIconName = convertedIconName.replace('md-', '');
    convertedIconName = convertedIconName.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    convertedIconName = convertedIconName.charAt(0).toUpperCase() + convertedIconName.slice(1);

    return 'Io' + convertedIconName;
  };
}