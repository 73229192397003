import * as Icons from "react-icons/io5";
import React from 'react';

import { observer } from 'mobx-react-lite';
import { Ionicons } from "../../utility/Ionicons";

type Props = {
  iconName: string;
  className?: string;
};

const ShowIonicon: React.FC<Props> = observer(({ iconName, className }) => {
  if (!iconName) {
    return null;
  }

  const ConvertedIcon = (Icons as any)[Ionicons.convertIconNameToComponentName(iconName)];

  if (ConvertedIcon) {
    return <ConvertedIcon className={className} />;
  }

  const AlternateConvertedIcon = (Icons as any)[Ionicons.convertIconNameToAlternateComponentName(iconName)];

  if (AlternateConvertedIcon) {
    return <AlternateConvertedIcon className={className} />;
  }

  console.warn(`Icon not found: ${iconName}`);

  return null;
});

export { ShowIonicon };
