import React from 'react';
import { observer } from 'mobx-react-lite';
import { ListGroup } from 'react-bootstrap';
import { useStores } from '../../hooks/useStores';
import { EditStudyCriteria } from './EditStudyCriteria.component';
import { StudyCriteria } from '../../models/StudyCriteria';
import { StudyListGroupItem } from '../common/StudyListGroupItem.component';
import { StudyCriteriaBreadcrumbs } from './StudyCriteriaBreadcrumbs.component';
import { useStudy } from '../../hooks/useStudy';

const StudyCriterias: React.FC<{}> = observer(() => {
  const { studyStore, studyCriteriaStore, asyncStore } = useStores();
  const { saveStudyConfig } = useStudy();

  const handleSelectStudyCriteria = (studyCriteria: StudyCriteria) => {
    asyncStore.showLoading(async () => {
      studyCriteriaStore.selectStudyCriteria(studyCriteria)
    });
  };

  const handleSave = () => {
    asyncStore.showLoading(async () => {
      studyCriteriaStore.applyChanges();
      await saveStudyConfig(studyStore.selectedStudyConfig);
    });
  };

  const handleCancel = () => {
    studyCriteriaStore.resetSelectedStudyCriteria();
  };

  const renderContent = () => {
    if (studyCriteriaStore.selectedStudyCriteria) {
      return (
        <EditStudyCriteria 
          studyCriteria={studyCriteriaStore.selectedStudyCriteria}
          multiCriteriaStudy={!studyCriteriaStore.isSingleCriteriaStudy()}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      );
    }

    return (
      <ListGroup as="ol">
        {
          studyCriteriaStore.studyCriterias.map((studyCriteria: StudyCriteria) => {
            return (
              <StudyListGroupItem
                onClick={() => handleSelectStudyCriteria(studyCriteria)}
                title={studyCriteria.title}
                icon={studyCriteria.icon}
              />
            );
          })
        }
      </ListGroup>
    );
  };

  return (
    <>
      <StudyCriteriaBreadcrumbs 
        studyCriteria={studyCriteriaStore.selectedStudyCriteria}
        onCancel={handleCancel}
      />

      {renderContent()}
    </>
  )
});

export { StudyCriterias };
