import { action, makeObservable, observable } from "mobx";
import { StudyConfig } from "../core/StudyConfig";

export class StudyConfigDetail {
  studyName: string;

  @observable
  sourceProtocolVersion: string;

  @observable
  protocolVersionLabel: string = '';

  @observable
  protocolCode: string = '';

  studyConfig: StudyConfig;

  private constructor(studyConfig?: StudyConfig) {
    makeObservable(this);

    if (studyConfig) {
      this.studyConfig = studyConfig;
      this.studyName = studyConfig.studyName;
      this.protocolVersionLabel = studyConfig.protocolVersionLabel;
      this.protocolCode = studyConfig.protocolCode;
    }
  }

  static createFromStudyConfig(studyConfig: StudyConfig) {
    return new StudyConfigDetail(studyConfig);
  }

  static createNewStudyConfig(sourceStudyConfig: StudyConfig) {
    const studyConfigDetail = new StudyConfigDetail();

    studyConfigDetail.studyName = sourceStudyConfig.studyName;
    const sourceProtocolVersion = sourceStudyConfig.protocolVersion;

    if (sourceProtocolVersion) {
      studyConfigDetail.setSourceProtocolVersion(sourceProtocolVersion);
    } else {
      throw new Error('Must have a sourceProtocolVersion');
    }

    return studyConfigDetail;
  }

  isNew() {
    return !this.studyConfig;
  }

  isSameProtocol(studyConfig: StudyConfig) {
    return this.studyConfig && this.studyConfig.studyId === studyConfig.studyId;
  }

  isComplete() {
    return this.protocolCode && this.protocolVersionLabel && this.sourceProtocolVersion;
  }
  
  toStudyConfig() {
    const studyConfig: StudyConfig = {
      studyName: this.studyName,
      protocolCode: this.protocolCode,
      protocolVersionLabel: this.protocolVersionLabel,
      sourceProtocolVersion: this.sourceProtocolVersion
    };

    return studyConfig;
  }

  @action
  setSourceProtocolVersion = (sourceProtocolVersion: string) => {
    this.sourceProtocolVersion = sourceProtocolVersion;
  }

  @action
  setProtocolVersionLabel = (protocolVersionLabel: string) => {
    this.protocolVersionLabel = protocolVersionLabel;
  }

  @action
  setProtocolCode = (protocolCode: string) => {
    this.protocolCode = protocolCode;
  }

  get createDate() {
    if (this.studyConfig && this.studyConfig.createDate) {
      return new Date(this.studyConfig.createDate);
    }
    
    return undefined;
  }

  get createUser() {
    return this.studyConfig?.createUser;
  }

  get updateDate() {
    if (this.studyConfig && this.studyConfig.updateDate) {
      return new Date(this.studyConfig.updateDate);
    }
    
    return undefined;
  }

  get updateUser() {
    return this.studyConfig.updateUser;
  }
}