import React from 'react';
import { observer } from 'mobx-react-lite';
import { EditNavSelection } from '../../models/EditNavSelection';
import { useStores } from '../../hooks/useStores';
import { Reminders } from '../reminders/Reminders.component';
import { VisitChecklists } from '../checklists/VisitChecklists.component';
import { EditLegend } from '../legend/EditLegend.component';
import { StudyCriterias } from '../criteria/StudyCriterias.component';

const EditStudyConfig: React.FC<{}> = observer(() => {
  const { studyStore } = useStores();
  
  switch (studyStore.navSelection) {
    case EditNavSelection.Criteria:
      return (<StudyCriterias />);
    case EditNavSelection.VisitChecklist:
      return (<VisitChecklists />);
    case EditNavSelection.VisitChecklistLegend:
      return (<EditLegend />);
    case EditNavSelection.Reminders:
      return (<Reminders />);
    default:
      return null;
  }
});

export { EditStudyConfig };
