import { action, makeObservable, observable } from 'mobx';
import { ProtocolConfig, StudyConfig } from '../core/StudyConfig';
import { EditNavSelection } from '../models/EditNavSelection';
import { StudyConfigDetail } from '../models/StudyConfigDetail';

export class StudyStore {
  @observable
  navSelection: EditNavSelection = EditNavSelection.StudyProtocolHome;

  @observable
  allStudyProtocols: StudyConfig[];

  @observable
  loaded: boolean = false;

  @observable
  selectedStudyConfigDetail: StudyConfigDetail;

  @observable
  newProtocolDetail: StudyConfig;

  @observable
  selectedStudyConfig: StudyConfig;

  constructor() {
    makeObservable(this);
  }

  @action
  initializeAllStudyProtocols(allStudyProtocols: StudyConfig[]) {
    this.allStudyProtocols = allStudyProtocols;
    this.selectStudyConfig(this.allStudyProtocols[0]);
    this.loadComplete();
  }

  @action
  loadComplete() {
    this.loaded = true;
  }

  @action
  showProtocolDetail(studyConfig: StudyConfig) {
    this.selectedStudyConfigDetail = StudyConfigDetail.createFromStudyConfig(studyConfig);
  }

  @action
  showNewProtocolDetail() {
    this.selectedStudyConfigDetail = StudyConfigDetail.createNewStudyConfig(this.allStudyProtocols[0]);
  }

  @action
  selectStudyConfig(studyConfig: StudyConfig) {
    this.selectedStudyConfig = studyConfig;
  }

  @action
  getStudyConfig() {
    return this.selectedStudyConfig;
  }

  @action
  getStudyProtocolConfig() {
    return this.selectedStudyConfig?.protocolConfig || {} as ProtocolConfig;
  }

  @action
  setNavSelection(navSelection: EditNavSelection) {
    this.navSelection = navSelection;
  }
}