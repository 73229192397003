import { action, makeObservable, observable } from "mobx";
import { StudyReminderConfig } from "../core/StudyConfig";
import { HtmlContent } from "../utility/HtmlContent";
import { v4 as uuidv4 } from 'uuid';

export class StudyReminder {
  _id: string = uuidv4();

  studyReminderConfig: StudyReminderConfig;

  @observable
  title: string = '';

  @observable
  icon: string = '';

  @observable
  key: string = '';

  @observable
  content: string = '';

  @observable
  tabsEnabled: boolean = false;

  @observable
  tabs: string[] = [];

  @observable
  tabContents: string[] = [];

  constructor(studyReminderConfig?: StudyReminderConfig) {
    makeObservable(this);

    if (studyReminderConfig) {
      this.studyReminderConfig = studyReminderConfig;
      this.title = studyReminderConfig.title;
      this.icon = studyReminderConfig.icon;
      this.key = studyReminderConfig.key;

      if (studyReminderConfig.tabs) {
        this.tabsEnabled = true;
        this.tabs = studyReminderConfig.tabs.slice();
        this.tabContents = (studyReminderConfig.content as [])
          .map((tabContent) => HtmlContent.convertForEditor(tabContent));
        this.content = '';
      } else {
        const content = typeof studyReminderConfig.content === 'string' ? studyReminderConfig.content : studyReminderConfig.content[0];
        this.content = HtmlContent.convertForEditor(content);
      }
    } else {
      this.studyReminderConfig = {
        title: '',
        key: '',
        content: '',
        icon: ''
      };
    }
  }

  static copy(studyReminder: StudyReminder) {
    const copy = new StudyReminder(studyReminder.studyReminderConfig);
    copy._id = studyReminder._id;

    return copy;
  }

  static createArrayFrom(studyReminderConfigs: StudyReminderConfig[]) {
    return studyReminderConfigs.map((studyReminderConfig) => {
      return new StudyReminder(studyReminderConfig);
    });
  }

  isTabbedContent() {
    return !!this.tabs;
  }

  @action
  setTitle(title: string) {
    this.title = title;
    this.key = this.title.replace(/\W/g, '');
  }

  @action
  setIcon(icon: string) {
    this.icon = icon;
  }

  @action
  setContent(content: string) {
    this.content = content;
  }

  @action
  setTab(index: number, tab: string) {
    this.tabs[index] = tab;
  }

  @action
  setTabContent(index: number, content: string) {
    this.tabContents[index] = content;
  }

  @action
  toggleTabs() {
    if (this.tabsEnabled) {
      this.content = this.tabs.map((tab, index) => {
        let tabContent = tab ? `<h2>${tab}</h2>` : '';

        return `${tabContent}${this.tabContents[index]}`;
      }).join(' ');

      this.tabsEnabled = false;
    } else {
      this.tabs = [];
      this.tabContents = [];
      this.addTab();

      this.tabs[0] = '';
      this.tabContents[0] = this.content;
      this.tabsEnabled = true;
    }
  }

  @action
  addTab() {
    this.tabs.push('');
    this.tabContents.push('');
  }

  @action
  removeTab(index: number) {
    this.tabs = this.tabs.filter((tab, i) => i !== index);
    this.tabContents = this.tabContents.filter((tab, i) => i !== index);
  }

  @action
  applyChanges() {
    this.studyReminderConfig.title = this.title;
    this.studyReminderConfig.icon = this.icon;
    this.studyReminderConfig.key = this.key;

    if (this.tabsEnabled) {
      this.studyReminderConfig.tabs = this.tabs;
      this.studyReminderConfig.content = this.tabContents.map(tabContent => HtmlContent.convertForApp(tabContent));
    } else {
      this.studyReminderConfig.tabs = undefined;
      this.studyReminderConfig.content = HtmlContent.convertForApp(this.content);
    }
  }
}