import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab, Form, InputGroup } from 'react-bootstrap';
import { RichEditor } from '../common/RichEditor.component';
import { SaveFooter } from '../common/SubmitFooter.component';
import { StudyCriteria } from '../../models/StudyCriteria';
import { ShowIonicon } from '../common/ShowIonicon.component';
import { IoniconModal } from '../common/IoniconModal.component';

type Props = {
  studyCriteria: StudyCriteria;
  multiCriteriaStudy?: boolean;
  onSave: () => void;
  onCancel: () => void;
};

const EditStudyCriteria: React.FC<Props> = observer(({ studyCriteria, multiCriteriaStudy, onSave, onCancel }) => {
  const [ selectedTab, setSelectedTab ] = useState("INCLUSION");
  const [ showIoniconModal, setShowIoniconModal ] = useState(false);

  const handleChangeTitle = (value: string) => {
    studyCriteria.setTitle(value);
  };

  const handleSelectIonicon = (value: string) => {
    studyCriteria.setIcon(value);
    setShowIoniconModal(false);
  };

  const renderTitleInput = () => {
    return (
      <Form.Group className="mb-3" controlId="formBasicTitle">
        <InputGroup>
          <InputGroup.Text title="Edit Icon" className="icon-edit-button" onClick={() => setShowIoniconModal(true)}>
            <ShowIonicon iconName={studyCriteria.icon || 'ellipse-outline'} />
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Enter title" value={studyCriteria.title} onChange={e => handleChangeTitle(e.target.value)} />
        </InputGroup>
      </Form.Group>
    );
  };

  return (
    <>
      { multiCriteriaStudy ? renderTitleInput() : null }
      
      <div className="margin-footer">
        <Tabs activeKey={selectedTab} onSelect={(eventKey) => setSelectedTab(eventKey as string)}>
          <Tab eventKey="INCLUSION" title="INCLUSION">
            <div className="margin-top">
              <RichEditor
                value={studyCriteria.inclusionCriteria}
                setValue={(value) => studyCriteria.setInclusionCriteria(value)}
              />
            </div>
          </Tab>

          <Tab eventKey="EXCLUSION" title="EXCLUSION">
            <div className="margin-top">
              <RichEditor
                value={studyCriteria.exclusionCriteria}
                setValue={(value) => studyCriteria.setExclusionCriteria(value)}
              />
            </div>
          </Tab>
        </Tabs>
      </div>

      <SaveFooter onSave={onSave} onCancel={onCancel} />

      <IoniconModal show={showIoniconModal} onSelect={handleSelectIonicon} onHide={() => setShowIoniconModal(false)} />
    </>
  )
});

export { EditStudyCriteria };
