import { action, makeObservable, observable } from "mobx";
import { StudyVisitConfig } from "../core/StudyConfig";
import { StudyVisitChecklistItem } from "./StudyVisitChecklistItem";

export class StudyVisit {
  studyVisitConfig: StudyVisitConfig;

  name: string;
  type: string;
  day: number;
  week: number;
  superscript: string;

  @observable
  checklistItems: StudyVisitChecklistItem[];

  constructor(studyVisitConfig?: StudyVisitConfig) {
    makeObservable(this);

    if (studyVisitConfig) {
      this.studyVisitConfig = studyVisitConfig;
      this.name = studyVisitConfig.name;
      this.type = studyVisitConfig.type;
      this.day = studyVisitConfig.day;
      this.week = studyVisitConfig.week;
      this.superscript = studyVisitConfig.superscript;
      this.checklistItems = StudyVisitChecklistItem.createFrom(studyVisitConfig);
    }
  }

  static createStudyVisitsFromConfig(studyVisitConfigs: StudyVisitConfig[]) {
    return studyVisitConfigs.map((studyVisitConfig) => {
      return new StudyVisit(studyVisitConfig);
    });
  }

  @action
  addChecklistItem() {
    this.checklistItems.push(new StudyVisitChecklistItem('', ''));
  }

  @action
  removeChecklistItem(index: number) {
    this.checklistItems = this.checklistItems.filter((checklistItem, i) => i !== index);
  }

  getTitle() {
    if (this.isScreening()) {
      return `Screening ${this.day}`;
    }

    if (this.isRunIn() || this.isOffTreatment()) {
      return this.name;
    }

    return `Week ${this.week}, Day ${this.day}`;
  };

  isScreening() {
    return this.type === 'SCREENING';
  }

  isRunIn() {
    return this.type === 'RUN_IN';
  }

  isOffTreatment() {
    return this.type === 'OFF_TREATMENT';
  }

  isNormal() {
    return this.type === 'NORMAL';
  }

  applyChanges() {
    this.studyVisitConfig.name = this.name;
    this.studyVisitConfig.type = this.type;
    this.studyVisitConfig.day = this.day;
    this.studyVisitConfig.week = this.week;
    this.studyVisitConfig.superscript = this.superscript;

    this.studyVisitConfig.actions = this.checklistItems.map((checklistItem) => {
      return checklistItem.getAction();
    });
  }
}