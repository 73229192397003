import { action, makeObservable, observable } from 'mobx';
import { StudyLegend } from '../models/StudyLegend';
import { EditNavSelection } from '../models/EditNavSelection';
import { StudyStore } from './StudyStore';

export class StudyLegendStore {
  @observable
  studyLegend: StudyLegend;

  constructor(private studyStore: StudyStore) {
    makeObservable(this);
  }

  @action
  editVisitChecklistLegend() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();

    this.studyLegend = new StudyLegend(studyProtocolConfig.legend);
    this.studyStore.setNavSelection(EditNavSelection.VisitChecklistLegend);
  }

  @action
  applyChanges() {
    this.studyLegend.applyChanges();
  }
}