import React from 'react';

import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { EditVisitChecklist } from './EditVisitChecklist.component';
import { StudyVisit } from '../../models/StudyVisit';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { useStudy } from '../../hooks/useStudy';
import { VisitListItems } from './VisitListItems.component';

const VisitChecklists: React.FC<{}> = observer(({ }) => {
  const { asyncStore, studyStore, studyVisitChecklistStore } = useStores();
  const { saveStudyConfig } = useStudy();

  const screenings = studyVisitChecklistStore.studyScreeningVisits;
  const normalVisits = studyVisitChecklistStore.studyNormalVisits;
  const offTreatmentVisits = studyVisitChecklistStore.studyOffTreatmentVisits;
  
  const studyProtocolConfig = studyStore.getStudyProtocolConfig();

  const handleSelectVisit = (studyVisit: StudyVisit) => {
    studyVisitChecklistStore.selectVisit(studyVisit);
  };

  const handleCopyStudyVisit = (studyVisit: StudyVisit) => {
    studyVisitChecklistStore.copyStudyVisit(studyVisit);
  };

  const handleOnSave = () => {
    asyncStore.showLoading(async () => {
      studyVisitChecklistStore.applyChanges();
      await saveStudyConfig(studyStore.selectedStudyConfig);
    });
  };

  const handleOnCancel = () => {
    studyVisitChecklistStore.resetVisit();
  }

  if (studyVisitChecklistStore.selectedVisit) {
    return (
      <EditVisitChecklist 
        visit={studyVisitChecklistStore.selectedVisit}
        legend={studyProtocolConfig.legend}
        onCopyStudyVisit={handleCopyStudyVisit}
        onSave={handleOnSave}
        onCancel={handleOnCancel}
      />
    );
  }

  return (
    <>
      <StudyBreadcrumbs activeTitle='Visit Checklist' />

      <VisitListItems 
        screenings={screenings}
        normalVisits={normalVisits}
        offTreatmentVisits={offTreatmentVisits}
        onSelectVisit={handleSelectVisit} />
    </>
  )
});

export { VisitChecklists };
