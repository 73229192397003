import React from 'react';

import { observer } from 'mobx-react-lite';
import { Card, ListGroup } from 'react-bootstrap';
import { StudyVisitListGroupItem } from '../common/StudyVisitListGroupItem.component';
import { StudyVisit } from '../../models/StudyVisit';

type Props = {
  screenings: StudyVisit[];
  normalVisits: StudyVisit[];
  offTreatmentVisits: StudyVisit[];
  onSelectVisit: (studyVisit: StudyVisit) => void;
};

const VisitListItems: React.FC<Props> = observer(({ screenings, normalVisits, offTreatmentVisits, onSelectVisit }) => {
  const handleSelectVisit = (studyVisit: StudyVisit) => {
    onSelectVisit(studyVisit);
  };

  const renderVisitListCard = (key: string, title: string, studyVisits: StudyVisit[]) => {
    return (
      <Card className="margin-bottom">
        <Card.Header>{title}</Card.Header>
        <ListGroup as="ol" variant="flush">
          {
            studyVisits.map((visit: StudyVisit, index: number) => {
              return (
                <StudyVisitListGroupItem key={`${key}-${index}`} visit={visit} onSelectVisit={handleSelectVisit} />
              )
            })
          }
        </ListGroup>
      </Card>
    );
  };

  return (
    <>
      {renderVisitListCard('screening-visit', 'Screenings', screenings)}
      {renderVisitListCard('normal-visit', 'Visits', normalVisits)}
      {renderVisitListCard('off-treatment-visit', 'Off Treatment Visits', offTreatmentVisits)}
    </>
  )
});

export { VisitListItems };
