import { action, makeObservable, observable } from "mobx";
import { StudyVisitConfig } from "../core/StudyConfig";

export class StudyVisitChecklistItem {
  @observable
  content: string;

  @observable
  superscript: string;

  constructor(content: string, superscript: string) {
    makeObservable(this);

    this.content = content;
    this.superscript = superscript;
  }

  static createFrom(visit: StudyVisitConfig) {
    return visit.actions.map((action) => {
      const parts = action.split('<sup>');

      return new StudyVisitChecklistItem(parts[0], parts[1] ? parts[1].replace('</sup>', '') : '');
    })
  }

  @action
  setContent(content: string) {
    this.content = content;
  }

  @action
  setSuperscript(superscript: string) {
    this.superscript = superscript;
  }

  @action
  getAction() {
    const sup = this.superscript ? `<sup>${this.superscript}</sup>` : '';

    return `${this.content}${sup}`;
  }
}