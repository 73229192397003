import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavDropdown } from 'react-bootstrap';

type Props = {
};

const StudyNavDropdown: React.FC<Props> = observer(() => {
  return (
    <NavDropdown title="STUDY">
      <NavDropdown.Item href='/study/falcon'>FALCON</NavDropdown.Item>
      <NavDropdown.Item href='/study/cypress'>CYPRESS</NavDropdown.Item>
    </NavDropdown>
  );
});

export { StudyNavDropdown };
