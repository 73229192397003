import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useStores } from '../../hooks/useStores';
import { AddButton } from '../common/AddButton.component';
import { SaveFooter } from '../common/SubmitFooter.component';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { EditNavSelection } from '../../models/EditNavSelection';
import { useStudy } from '../../hooks/useStudy';
import { LegendTable } from './LegendTable.component';
import { StudyLegend } from '../../models/StudyLegend';
import { FaSortAlphaDown } from 'react-icons/fa';

type Props = {
};

const EditLegend: React.FC<Props> = observer(() => {
  const { studyLegendStore, studyStore, asyncStore } = useStores();
  const { saveStudyConfig } = useStudy();

  const studyLegend = studyLegendStore.studyLegend;
  const footnoteKeys = StudyLegend.footnoteKeys();

  const handleAddFootnote = () => {
    studyLegend.addFootnote();
  };

  const handleChangeFootnote = (index: number, value: string) => {
    studyLegend.changeFootnote(index, value);
  };
  
  const handleRemoveFootnote = (index: number) => {
    studyLegend.removeFootnote(index);
  };

  const handleAddAbbreviation = () => {
    studyLegend.addAbbreviation();
  };

  const handleSort = () => {
    studyLegend.sortAbbreviations();
  };

  const handleChangeAbbreviation = (index: number, value: string) => {
    studyLegend.changeAbbreviation(index, value);
  };

  const handleRemoveAbbreviation = (index: number) => {
    studyLegend.removeAbbreviation(index);
  };

  const handleOnSave = () => {
    asyncStore.showLoading(async () => {
      studyLegendStore.applyChanges();
      await saveStudyConfig(studyStore.selectedStudyConfig);
    });
  };

  const handleOnCancel = () => {
    studyStore.setNavSelection(EditNavSelection.StudyProtocolHome);
  };

  const renderFootnoteRow = (footnote: string, index: number) => {
    return (
      <InputGroup>
        <InputGroup.Text className="legend-key">
          {`${footnoteKeys[index]}.`}
        </InputGroup.Text>

        <Form.Control 
          type="text" 
          placeholder="Enter footnote" 
          value={footnote}
          onChange={(e) => handleChangeFootnote(index, e.target.value)}
        />
      </InputGroup>
    );
  };

  const renderAbbreviationRow = (abbreviation: string, index: number) => {
    return (
      <Form.Control 
        type="text" 
        placeholder="Enter abbreviation" 
        value={abbreviation}
        onChange={(e) => handleChangeAbbreviation(index, e.target.value)}
      />
    );
  }

  return (
    <>
      <StudyBreadcrumbs activeTitle="Visit Checklist Legend" />

      <>
        <div>
          <LegendTable
            tableKey="footnote"
            rows={studyLegend.orderedList}
            title="Footnotes"
            handleRemove={handleRemoveFootnote}
            renderRow={renderFootnoteRow}
          />
        </div>
        
        <div>
          <AddButton onClick={handleAddFootnote} />
        </div>

        <div className="margin-top">
          <LegendTable
            tableKey="abbreviation"
            rows={studyLegend.abbreviations}
            title="Abbreviations"
            handleRemove={handleRemoveAbbreviation}
            renderRow={renderAbbreviationRow}
          />
        </div>

        <div className="margin-footer">
          <AddButton onClick={handleAddAbbreviation} />
          {' '}
          <Button variant="primary" onClick={handleSort}><FaSortAlphaDown /> Sort</Button>
        </div>
        
        <SaveFooter onSave={handleOnSave} onCancel={handleOnCancel} />
      </>
    </>
  );
});

export { EditLegend };
