import { action, makeObservable, observable } from 'mobx';
import { StudyCriteriaConfig } from '../core/StudyConfig';
import { EditNavSelection } from '../models/EditNavSelection';
import { StudyCriteria } from '../models/StudyCriteria';
import { StudyStore } from './StudyStore';

export class StudyCriteriaStore {
  @observable
  studyCriterias: StudyCriteria[];

  @observable
  selectedStudyCriteria: StudyCriteria | undefined;

  constructor(private studyStore: StudyStore) {
    makeObservable(this);
  }

  isSingleCriteriaStudy() {
    return this.studyCriterias.length === 1;
  }

  @action
  loadStudyCriteria() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();

    if (Array.isArray(studyProtocolConfig.criteria)) {
      this.studyCriterias = studyProtocolConfig.criteria.map((criteriaConfig) => new StudyCriteria(criteriaConfig));
    } else {
      this.studyCriterias = [ new StudyCriteria(studyProtocolConfig.criteria) ];
    }
  }

  @action
  editInclusionExclusionCriteria() {
    this.loadStudyCriteria();

    if (this.isSingleCriteriaStudy()) {
      this.selectStudyCriteria(this.studyCriterias[0]);
    }

    this.studyStore.setNavSelection(EditNavSelection.Criteria);
  }

  @action
  selectStudyCriteria(studyCriteria: StudyCriteria) {
    this.selectedStudyCriteria = studyCriteria;
  }

  @action
  resetSelectedStudyCriteria() {
    this.loadStudyCriteria();
    this.selectedStudyCriteria = undefined;
  }

  @observable
  applyChanges() {
    const studyConfig = this.studyStore.getStudyConfig();
    this.selectedStudyCriteria?.applyChanges(studyConfig);
  }
}