import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IoAlarmSharp, IoCheckboxOutline, IoClipboardOutline, IoListOutline } from 'react-icons/io5';
import { TileCard } from '../common/TileCard.component';
import { useStores } from '../../hooks/useStores';
import { EditNavSelection } from '../../models/EditNavSelection';
import { EditStudyConfig } from './EditStudyConfig.component';
import { Container } from 'react-bootstrap';
import { Header } from '../common/Header.component';
import { useStudy } from '../../hooks/useStudy';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { StudyProtocolSelection as StudyProtocolActions } from './StudyProtocolSelection.component';
import { StudyProtocolDetail } from './StudyProtocolDetail.component';
import { StudyConfig } from '../../core/StudyConfig';

type Props = {
  studyName: string;
};

const StudyHome: React.FC<Props> = observer(({ studyName }) => {
  const { 
    studyStore, 
    studyCriteriaStore, 
    studyVisitChecklistStore, 
    studyRemindersStore,
    studyLegendStore,
    asyncStore
  } = useStores();

  const { loadStudyProtocols } = useStudy();
  const [ showProtocolDetail, setShowProtocolDetail ] = useState(false);

  useEffect(() => {
    asyncStore.showLoading(async () => {
      const result = await loadStudyProtocols(studyName);
      studyStore.initializeAllStudyProtocols(result.studyProtocols);
    });
  }, []);

  const handleSelectCriteria = () => {
    asyncStore.showLoading(async () => {
      studyCriteriaStore.editInclusionExclusionCriteria();
    });
  };

  const handleSelectVisitChecklist = () => {
    asyncStore.showLoading(async () => {
      studyVisitChecklistStore.editVisitChecklists();
    });
  };

  const handleSelectVisitChecklistLegend = () => {
    asyncStore.showLoading(async () => {
      studyLegendStore.editVisitChecklistLegend();
    });
  };

  const handleSelectReminders = () => {
    asyncStore.showLoading(async () => {
      studyRemindersStore.editReminders();
    });
  };

  const handleNewProtocol = () => {
    asyncStore.showLoading(async () => {
      studyStore.showNewProtocolDetail();
      setShowProtocolDetail(true);
    });
  };

  const handleShowProtocol = (studyConfig: StudyConfig) => {
    asyncStore.showLoading(async () => {
      studyStore.showProtocolDetail(studyConfig);
      setShowProtocolDetail(true);
    });
  };

  const renderTiles = () => {
    return (
      <>
        <StudyBreadcrumbs 
          actions={
            <StudyProtocolActions
              onNewProtocol={handleNewProtocol}
              onShowProtocol={handleShowProtocol}
            />
          }
        />
        
        <StudyProtocolDetail 
          studyConfigDetail={studyStore.selectedStudyConfigDetail}
          show={showProtocolDetail} 
          onHide={() => setShowProtocolDetail(false)} />

        <div className="tile-container">
          <div className="tile-card-container">
            <TileCard
              title='Inclusion/Exclusion Criteria'
              iconComponent={<IoCheckboxOutline className="tile-icon" />}
              onClick={handleSelectCriteria}
            />
          </div>

          <div className="tile-card-container">
            <TileCard
              title='Visit Checklist'
              iconComponent={<IoClipboardOutline className="tile-icon" />}
              onClick={handleSelectVisitChecklist}
            />
          </div>

          <div className="tile-card-container">
            <TileCard
              title='Visit Reminders/Tips'
              iconComponent={<IoAlarmSharp className="tile-icon" />}
              onClick={handleSelectReminders}
            />
          </div>

          <div className="tile-card-container">
            <TileCard
              title='Visit Checklist Legend'
              iconComponent={<IoListOutline className="tile-icon" />}
              onClick={handleSelectVisitChecklistLegend}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="full-width">
      <Header />

      {
        studyStore.loaded ?
          <Container fluid>
            {studyStore.navSelection === EditNavSelection.StudyProtocolHome ? renderTiles() : <EditStudyConfig />}
          </Container> : null
      }
    </div>
  );
});

export { StudyHome };