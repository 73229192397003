import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { IoAdd } from "react-icons/io5";
import { StudyReminder } from "../../models/StudyReminder";
import { ReminderContent } from "./ReminderContent.component";

type Props = {
  studyReminder: StudyReminder;
};

const ReminderContentTabs: React.FC<Props> = observer(({ studyReminder }) => {
  const [ selectedTab, setSelectedTab ] = useState("0");

  const handleChangeTabName = (index: number, value: string) => {
    studyReminder.tabs[index] = value;
  };

  const handleChangeTabContent = (index: number, value: string) => {
    studyReminder.tabContents[index] = value;
  };

  return (
    <Tabs
      activeKey={selectedTab}
      onSelect={(key) => {
        if (key === 'Add') {
          if (studyReminder.tabs) {
            setSelectedTab(studyReminder.tabs.length + '');
            studyReminder.addTab();
          }
        } else {
          setSelectedTab(key as string);
        }
      }}
      className="mb-3"
    >
      {
        studyReminder.tabs.map((tab, index) => {
          return (
            <Tab key={`tab-${index}`} eventKey={index + ''} title={`Tab ${index + 1}`}>
              <Form.Group className="mb-3" controlId="formBasicTitle">
                <Form.Control 
                  type="text" 
                  placeholder="Enter tab name" 
                  value={studyReminder.tabs[index]} 
                  onChange={(e) => handleChangeTabName(index, e.target.value)} />
              </Form.Group>

              <ReminderContent
                value={studyReminder.tabContents[index]}
                setValue={(value) => handleChangeTabContent(index, value)}
              />
            </Tab>
          );
        })
      }

      <Tab eventKey="Add" title={<IoAdd />} />
    </Tabs>
  );
});

export { ReminderContentTabs };