import React from 'react';
import { observer } from 'mobx-react-lite';

import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { StudyCriteria } from '../../models/StudyCriteria';

type Props = {
  studyCriteria?: StudyCriteria;
  onCancel: () => void;
};

const StudyCriteriaBreadcrumbs: React.FC<Props> = observer(({ studyCriteria, onCancel }) => {
  if (!studyCriteria || !studyCriteria.title) {
    return (
      <StudyBreadcrumbs activeTitle="Inclusion/Exclusion Criteria" />
    );
  }

  return (
    <StudyBreadcrumbs 
      breadcrumbItems={[
        {
          title: 'Inclusion/Exclusion Criteria',
          onClick: onCancel
        }
      ]} 
      activeTitle={studyCriteria.title}
    />
  );
});

export { StudyCriteriaBreadcrumbs };
