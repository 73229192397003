import { action, makeObservable, observable } from 'mobx';
import { EditNavSelection } from '../models/EditNavSelection';
import { StudyReminder } from '../models/StudyReminder';
import { StudyStore } from './StudyStore';

export class StudyRemindersStore {
  @observable
  studyReminders: StudyReminder[];

  @observable
  selectedReminder: StudyReminder | undefined;

  newReminder: boolean;

  constructor(private studyStore: StudyStore) {
    makeObservable(this);
  }

  @action
  editReminders() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();
    this.studyReminders = StudyReminder.createArrayFrom(studyProtocolConfig.reminders);
    this.resetSelectedReminder();
    this.studyStore.setNavSelection(EditNavSelection.Reminders);
  }

  @action
  selectReminder(studyReminder: StudyReminder) {
    this.selectedReminder = StudyReminder.copy(studyReminder);
  }

  @action
  resetSelectedReminder() {
    this.selectedReminder = undefined;
    this.newReminder = false;
  }

  @action
  addReminder() {
    this.selectedReminder = new StudyReminder();
    this.newReminder = true;
  }

  @action
  applyChanges() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();

    if (this.selectedReminder) {
      const studyReminderCopy = StudyReminder.copy(this.selectedReminder);

      if (this.newReminder) {
        this.studyReminders.push(studyReminderCopy);
        this.newReminder = false;
      } else {
        const index = this.studyReminders.findIndex((studyReminder) => studyReminder._id === this.selectedReminder?._id);
        this.studyReminders[index] = studyReminderCopy;
      }

      studyProtocolConfig.reminders = this.studyReminders.map(studyReminder => studyReminder.studyReminderConfig);
    }
  }
}