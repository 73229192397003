import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { IoCheckboxOutline, IoCopyOutline, IoListOutline } from 'react-icons/io5';
import { LegendModal } from '../common/LegendModal.component';
import { StudyVisit } from '../../models/StudyVisit';
import { SaveFooter } from '../common/SubmitFooter.component';
import { AddButton } from '../common/AddButton.component';
import { RemoveButton } from '../common/RemoveButton.component';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { DropResult } from 'react-beautiful-dnd';
import { ReorderChecklistModal } from './ReorderChecklistModal.component';
import { StudyLegendConfig } from '../../core/StudyConfig';
import { SelectVisitModal } from './SelectVisitModal.component';

type Props = {
  visit: StudyVisit;
  legend: StudyLegendConfig;
  onCopyStudyVisit: (studyVisit: StudyVisit) => void;
  onSave: () => void;
  onCancel: () => void;
};

const EditVisitChecklist: React.FC<Props> = observer(({ visit, legend, onCopyStudyVisit, onSave, onCancel }) => {
  const [ showLegend, setShowLegend ] = useState(false);
  const [ showSelectVisit, setShowSelectVisit ] = useState(false);
  const [ legendRow, setLegendRow ] = useState(0);
  const [ showReorder, setShowReorder ] = useState(false);

  const handleShowLegend = (index: number) => {
    setShowLegend(true);
    setLegendRow(index);
  };

  const handleSelectFootnote = (superscript: string) => {
    visit.checklistItems[legendRow].setSuperscript(superscript);
    setShowLegend(false);
    setLegendRow(0);
  };

  const handleAddChecklistItem = () => {
    visit.addChecklistItem();
  };

  const handleChangeChecklistItemContent = (index: number, value: string) => {
    visit.checklistItems[index].setContent(value);
  }

  const handleChangeChecklistItemSuperscript = (index: number, value: string) => {
    visit.checklistItems[index].setSuperscript(value);
  }

  const handleOnDragEnd = (result: DropResult) => {
    const items =  visit.checklistItems;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination?.index || 0, 0, reorderedItem);
    visit.checklistItems = items;
  };

  const handleCopyStudyVisit = (studyVisit: StudyVisit) => {
    onCopyStudyVisit(studyVisit);
    setShowSelectVisit(false);
  };

  const handleOnSave = () => {
    onSave();
  };

  const handleOnCancel = () => {
    onCancel();
  };

  const renderEditChecklist = () => {
    return (
      <>
        <StudyBreadcrumbs breadcrumbItems={[
          {
            title: 'Visit Checklist',
            onClick: onCancel
          }
        ]} activeTitle={visit.getTitle()} />

        <>
          <table>
            <tbody>
              {
                visit.checklistItems.map((item, index) => {
                  return (
                    <tr key={`checklist-item-${index}`}>
                      <td className="checklist-input-column">
                        <InputGroup>
                          <InputGroup.Text>
                            <IoCheckboxOutline />
                          </InputGroup.Text>
                          <Form.Control 
                            type="text" 
                            placeholder="Enter checklist item" 
                            value={item.content}
                            onChange={(e) => handleChangeChecklistItemContent(index, e.target.value)}
                          />
                        </InputGroup>
                      </td>
                      <td>
                        <InputGroup>
                          <Form.Control 
                            type="text" 
                            placeholder="Superscript" 
                            value={item.superscript}
                            className="superscript-input"
                            onChange={(e) => handleChangeChecklistItemSuperscript(index, e.target.value)}
                          />
                          <Button title="Show Legend" variant="outline-primary" style={{ marginRight: 2 }} onClick={() => handleShowLegend(index)}>
                            <IoListOutline />
                          </Button>
                        </InputGroup>
                      </td>
                      <td className="button-column">
                        <RemoveButton onClick={(() => visit.removeChecklistItem(index))} />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>

          <div className="margin-top margin-footer">
            <AddButton onClick={handleAddChecklistItem} />
            {' '}
            <Button onClick={() => setShowReorder(true)}>
              Reorder
            </Button>
            {' '}
            <Button variant="outline-secondary" onClick={() => setShowSelectVisit(true)}>
              <IoCopyOutline /> Copy Visit
            </Button>
          </div>

          <LegendModal 
            legend={legend} 
            onSelect={handleSelectFootnote}
            onHide={() => setShowLegend(false)}
            show={showLegend} 
          />

          <SaveFooter onSave={handleOnSave} onCancel={handleOnCancel} />
        </>

        <ReorderChecklistModal
          studyVisit={visit}
          show={showReorder}
          onHide={() => setShowReorder(false)}
          onDragEnd={handleOnDragEnd}
        />

        <SelectVisitModal
          show={showSelectVisit}
          onHide={() => setShowSelectVisit(false)}
          onSelectVisit={handleCopyStudyVisit}
        />
      </>
    );
  };

  return renderEditChecklist();
});

export { EditVisitChecklist };
