import React from 'react';
import { observer } from 'mobx-react-lite';
import { RemoveButton } from '../common/RemoveButton.component';
import { ContentHeader } from '../common/ContentHeader.component';

type Props = {
  tableKey: string;
  title: string;
  rows: string[];
  renderRow: (row: string, index: number) => React.ReactNode;
  handleRemove: (index: number) => void;
};

const LegendTable: React.FC<Props> = observer(({ tableKey, title, rows, renderRow, handleRemove }) => {
  return (
    <>
      <ContentHeader title={title} />

      <table className="legend-table">
        <tbody>
          {
            rows.map((row, index) => {
              return (
                <tr key={`${tableKey}-${index}`}>
                  <td>
                    {renderRow(row, index)}
                  </td>

                  <td className="button-column">
                    <RemoveButton onClick={() => handleRemove(index)} />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  );
});

export { LegendTable };
