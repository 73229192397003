import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ListGroup } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useStores } from '../../hooks/useStores';
import { EditReminder } from './EditReminder.component';
import { StudyListGroupItem } from '../common/StudyListGroupItem.component';
import { StudyReminder } from '../../models/StudyReminder';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { SaveFooter } from '../common/SubmitFooter.component';
import { AddButton } from '../common/AddButton.component';
import { EditNavSelection } from '../../models/EditNavSelection';
import { useStudy } from '../../hooks/useStudy';

const Reminders: React.FC<{}> = observer(({ }) => {
  const { asyncStore, studyStore, studyRemindersStore } = useStores();
  const [ showSaveFooter, setShowSaveFooter ] = useState(false);
  const { saveStudyConfig } = useStudy();

  const handleOnSave = () => {
    asyncStore.showLoading(async () => {
      studyRemindersStore.applyChanges();
      await saveStudyConfig(studyStore.selectedStudyConfig);
    });
  };

  const handleOnCancel = () => {
    studyStore.setNavSelection(EditNavSelection.StudyProtocolHome);
  };

  const handleOnCancelEditReminder = () => {
    studyRemindersStore.resetSelectedReminder();
  };

  if (studyRemindersStore.selectedReminder) {
    return (
      <EditReminder
        studyReminder={studyRemindersStore.selectedReminder}
        onSave={handleOnSave}
        onCancel={handleOnCancelEditReminder}
      />
    );
  };

  const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const items = studyRemindersStore.studyReminders;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination?.index || 0, 0, reorderedItem);
    studyRemindersStore.studyReminders = items;
    setShowSaveFooter(true);
  };

  const handleAddReminder = () => {
    studyRemindersStore.addReminder();
  };

  return (
    <>
      <StudyBreadcrumbs activeTitle="Visit Reminders/Tips" />

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {
            (provided) => (
              <ListGroup as="ol" {...provided.droppableProps} ref={provided.innerRef}>
                {
                  studyRemindersStore.studyReminders.map((reminder: StudyReminder, index: number) => {
                    return (
                      <Draggable key={`reminder-${reminder._id}`} draggableId={reminder._id} index={index}>
                        {
                          (provided) => (
                            <StudyListGroupItem
                              title={reminder.title}
                              icon={reminder.icon}
                              onClick={() => studyRemindersStore.selectReminder(reminder)}
                              draggableProvided={provided}
                            />
                          )
                        }
                      </Draggable>
                    );
                  })
                }
                {provided.placeholder}
              </ListGroup>
            )
          }
        </Droppable>
      </DragDropContext>

      <div className="margin-top margin-footer">
        <AddButton onClick={handleAddReminder} />
      </div>

      {showSaveFooter ? <SaveFooter onSave={handleOnSave} onCancel={handleOnCancel} /> : null}
    </>
  )
});

export { Reminders };
