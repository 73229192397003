import { StudyConfig } from "../core/StudyConfig";
import { EnvConfig } from "../utility/EnvConfig";
import { useAxios } from "./useAxios";

export function useStudy() {
  const { createAxiosInstance } = useAxios();
  
  const baseUrl = EnvConfig.get('awsBaseServiceUrl');

  const loadStudyProtocols = async (studyName: string) => {
    const axios = await createAxiosInstance();
    const studyProtocols = await axios.get(`${baseUrl}/study-cms-service?studyName=${studyName}`);
    
    return studyProtocols.data;
  }

  const saveStudyConfig = async (studyConfig: StudyConfig) => {
    const axios = await createAxiosInstance();
    const result = await axios.put(`${baseUrl}/study-cms-service?studyName=${studyConfig.studyId}`, studyConfig);
    
    return result;
  }

  const createStudyConfig = async (studyConfig: StudyConfig) => {
    const axios = await createAxiosInstance();
    const result = await axios.post(`${baseUrl}/study-cms-service`, studyConfig);
    
    return result;
  }

  return { loadStudyProtocols, saveStudyConfig, createStudyConfig };
}