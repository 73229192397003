import React from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb } from 'react-bootstrap';
import { useStores } from '../../hooks/useStores';
import { EditNavSelection } from '../../models/EditNavSelection';

type BreadcrumbItem = {
  title: string;
  onClick: () => void;
};

type Props = {
  breadcrumbItems?: BreadcrumbItem[];
  activeTitle?: string;
  actions?: React.ReactNode;
};

const StudyBreadcrumbs: React.FC<Props> = observer(({ breadcrumbItems = [], activeTitle, actions }) => {
  const { studyStore } = useStores();

  const handleStudyHome = () => {
    studyStore.setNavSelection(EditNavSelection.StudyProtocolHome);
  };

  const studyName = studyStore.selectedStudyConfig.studyName;
  const studyNameLower = studyName.toLowerCase()
  const studyLogo = `${studyNameLower}_logo_small.jpg`;

  return (
    <>
      <Breadcrumb className={`study-breadcrumbs ${studyNameLower}-breadcrumbs`}>
        <Breadcrumb.Item className="study-breadcrumb-text" active={!activeTitle} onClick={handleStudyHome}>
          <img src={`/${studyLogo}`} className={`${studyNameLower}-breadcrumb-logo study-breadcrumb-logo`} />
          {studyName} ({studyStore.selectedStudyConfig.protocolVersionLabel})
          {actions}
        </Breadcrumb.Item>
        
        {
          breadcrumbItems.map((item, index) => {
            return (<Breadcrumb.Item key={`breadcrumb-${index}`} onClick={item.onClick}>{item.title}</Breadcrumb.Item>);
          })
        }
        
        {activeTitle ? <Breadcrumb.Item active>{activeTitle}</Breadcrumb.Item> : null}
      </Breadcrumb>
    </>
  );
});

export { StudyBreadcrumbs };