import React from 'react';

import { observer } from 'mobx-react-lite';
import { Header } from './common/Header.component';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TileCard } from './common/TileCard.component';

type Props = {
};

const Home: React.FC<Props> = observer(({ }) => {
  let navigate = useNavigate();

  return (
    <>
      <Header />

      <Container fluid className="margin-top">
        <div className="tile-container">
          <div className="tile-card-container">
            <TileCard
              imageSrc='/falcon.jpg'
              onClick={() => navigate('/study/falcon')}
              customClassName="falcon"
            />
          </div>

          <div className="tile-card-container">
            <TileCard
              imageSrc='/cypress.jpg'
              onClick={() => navigate('/study/cypress')}
              customClassName="cypress"
            />
          </div>
        </div>
      </Container>
    </>
  );
});

export { Home };