import { action, makeObservable, observable } from "mobx";
import { StudyConfig, StudyCriteriaConfig } from "../core/StudyConfig";
import { HtmlContent } from "../utility/HtmlContent";

export class StudyCriteria {
  type: string;

  @observable
  title: string;

  @observable
  icon: string;

  @observable
  inclusionCriteria: string;

  @observable
  exclusionCriteria: string;
  
  constructor(studyCriteriaConfig?: StudyCriteriaConfig) {
    makeObservable(this);

    if (studyCriteriaConfig) {
      this.type = studyCriteriaConfig.type;
      this.title = studyCriteriaConfig.title;
      this.icon = studyCriteriaConfig.icon;

      this.inclusionCriteria = HtmlContent.convertForEditor(studyCriteriaConfig.content[0]);
      this.exclusionCriteria = HtmlContent.convertForEditor(studyCriteriaConfig.content[1]);
    }
  }

  static createFrom(studyCriteriaConfig?: StudyCriteriaConfig): StudyCriteria {
    return new StudyCriteria(studyCriteriaConfig);
  }

  @action
  setTitle(title: string) {
    this.title = title;
  }

  @action
  setIcon(icon: string) {
    this.icon = icon;
  }

  @action
  setInclusionCriteria(inclusionCriteria: string) {
    this.inclusionCriteria = inclusionCriteria;
  }

  @action
  setExclusionCriteria(exclusionCriteria: string) {
    this.exclusionCriteria = exclusionCriteria;
  }

  @action
  applyChanges(studyConfig: StudyConfig) {
    const studyConfigCriteria = studyConfig.protocolConfig?.criteria;

    const modifiedStudyConfigCriteria = Array.isArray(studyConfigCriteria) ?
      studyConfigCriteria.find((criteria) => criteria.type === this.type) :
      studyConfigCriteria;

    if (modifiedStudyConfigCriteria) {
      modifiedStudyConfigCriteria.title = this.title;
      modifiedStudyConfigCriteria.icon = this.icon;
      modifiedStudyConfigCriteria.content[0] = HtmlContent.convertForApp(this.inclusionCriteria);
      modifiedStudyConfigCriteria.content[1] = HtmlContent.convertForApp(this.exclusionCriteria);
    }
  }
}