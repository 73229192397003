import React from 'react';

import { observer } from 'mobx-react-lite';
import { StudyVisit } from '../../models/StudyVisit';
import { StudyListGroupItem } from './StudyListGroupItem.component';

type Props = {
  visit: StudyVisit;
  onSelectVisit: (visit: StudyVisit) => void;
};

const StudyVisitListGroupItem: React.FC<Props> = observer(({ visit, onSelectVisit }) => {
  return (
    <StudyListGroupItem
      title={visit.getTitle()}
      superscript={visit.superscript}
      onClick={() => onSelectVisit(visit)}
    />
  );
});

export { StudyVisitListGroupItem };
