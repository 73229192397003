import { action, makeObservable, observable } from "mobx";
import { StudyLegendConfig } from "../core/StudyConfig";

export class StudyLegend {
  studyLegendConfig: StudyLegendConfig;

  @observable
  orderedList: string[];

  @observable
  abbreviations: string[];

  constructor(studyLegendConfig: StudyLegendConfig) {
    makeObservable(this);

    this.studyLegendConfig = studyLegendConfig;
    this.orderedList = studyLegendConfig?.orderedList?.slice() || [];
    this.abbreviations = studyLegendConfig?.abbreviations?.slice() || [];
  }

  static createFrom(studyLegendConfig: StudyLegendConfig): StudyLegend {
    return new StudyLegend(studyLegendConfig);
  }

  static footnoteKeys() {
    return 'abcdefghijklmnopqrstuvwxyz'.split('');
  }

  @action
  addFootnote() {
    this.orderedList.push('');
  }

  @action
  changeFootnote(index: number, value: string) {
    this.orderedList[index] = value;
  }

  @action
  removeFootnote(index: number) {
    this.orderedList = this.orderedList.filter((value, i) => i !== index);
  };

  @action
  addAbbreviation() {
    this.abbreviations.push('');
  }

  @action
  sortAbbreviations() {
    this.abbreviations = this.abbreviations.sort((a, b) => a.localeCompare(b, 'en', {'sensitivity': 'base'}));
  }

  @action
  changeAbbreviation(index: number, value: string) {
    this.abbreviations[index] = value;
  }
  

  @action
  removeAbbreviation(index: number) {
    this.abbreviations = this.abbreviations.filter((value, i) => i !== index);
  }

  @action
  applyChanges() {
    this.studyLegendConfig.orderedList = this.orderedList.slice();
    this.studyLegendConfig.abbreviations = this.abbreviations.slice();
  }
}