import React from 'react';
import { observer } from 'mobx-react-lite';
import { ListGroup, Modal } from 'react-bootstrap';
import { StudyVisit } from '../../models/StudyVisit';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { StudyListGroupItem } from '../common/StudyListGroupItem.component';

type Props = {
  show: boolean;
  studyVisit: StudyVisit;
  onHide: () => void;
  onDragEnd: (result: DropResult) => void;
};

const ReorderChecklistModal: React.FC<Props> = observer(({ studyVisit, show, onHide, onDragEnd }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-70w">
      <Modal.Header closeButton>
        Reorder Visit Checklist
      </Modal.Header>
      <Modal.Body>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="characters">
            {
              (provided) => (
                <ListGroup {...provided.droppableProps} ref={provided.innerRef}>
                  {
                    studyVisit.checklistItems.map((item, index) => {
                      return (
                        <Draggable key={`checklist-item-${index}`} draggableId={`checklist-item-${index}`} index={index}>
                          {
                            (provided) => (
                              <StudyListGroupItem
                                title={item.content}
                                superscript={item.superscript}
                                fontSize={20}
                                icon={'checkbox'}
                                draggableProvided={provided}
                              />
                            )
                          }
                        </Draggable>
                      );
                    })
                  }
                  {provided.placeholder}
                </ListGroup>
              )
            }
          </Droppable>
        </DragDropContext>
      </Modal.Body>
    </Modal>
  );
});

export { ReorderChecklistModal };
