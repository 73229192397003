import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { Form, InputGroup } from 'react-bootstrap';
import { ShowIonicon } from '../common/ShowIonicon.component';
import { StudyReminder } from '../../models/StudyReminder';
import { SaveFooter } from '../common/SubmitFooter.component';
import { StudyBreadcrumbs } from '../common/StudyBreadcrumbs.component';
import { IoniconModal } from '../common/IoniconModal.component';
import { ReminderContentTabs } from './ReminderContentTabs.component';
import { ReminderContent } from './ReminderContent.component';

type Props = {
  studyReminder: StudyReminder;
  onSave: () => void;
  onCancel: () => void;
};

const EditReminder: React.FC<Props> = observer(({ studyReminder, onSave, onCancel }) => {
  const [ showIoniconModal, setShowIoniconModal ] = useState(false);

  const handleChangeTitle = (value: string) => {
    studyReminder.setTitle(value);
  };

  const handleSelectIonicon = (value: string) => {
    studyReminder.setIcon(value);
    setShowIoniconModal(false);
  };

  const handleOnSave = () => {
    studyReminder.applyChanges();
    onSave();
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <>
      <StudyBreadcrumbs breadcrumbItems={[
        {
          title: 'Visit Reminders/Tips',
          onClick: onCancel
        }
      ]} activeTitle={studyReminder.title || 'New'} />

      <Form.Group className="mb-3" controlId="formBasicTitle">
        <InputGroup>
          <InputGroup.Text title="Edit Icon" className="icon-edit-button" onClick={() => setShowIoniconModal(true)}>
            <ShowIonicon iconName={studyReminder.icon || 'ellipse-outline'} />
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Enter title" value={studyReminder.title} onChange={e => handleChangeTitle(e.target.value)} />
        </InputGroup>
      </Form.Group>

      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Tabs"
        checked={studyReminder.tabsEnabled}
        onChange={() => studyReminder.toggleTabs()}
      />

      <div className="margin-top margin-footer">
        {
          studyReminder.tabsEnabled ? 
            <ReminderContentTabs studyReminder={studyReminder} /> : 
            <ReminderContent value={studyReminder.content} setValue={(value) => studyReminder.setContent(value)} />
        }
      </div>

      <SaveFooter onSave={handleOnSave} onCancel={handleOnCancel} />

      <IoniconModal show={showIoniconModal} onSelect={handleSelectIonicon} onHide={() => setShowIoniconModal(false)} />
    </>
  );
});

export { EditReminder };
