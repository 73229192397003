import React from 'react';

import { observer } from 'mobx-react-lite';
import { ListGroup } from 'react-bootstrap';
import { ShowIonicon } from './ShowIonicon.component';
import { DraggableProvided } from 'react-beautiful-dnd';

type Props = {
  title: string;
  icon?: string;
  fontSize?: number;
  superscript?: string;
  draggableProvided?: DraggableProvided;
  onClick?: () => void;
};

const StudyListGroupItem: React.FC<Props> = observer(({ title, icon, fontSize = 30, superscript, draggableProvided, onClick }) => {
  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
      action={!!onClick}
      onClick={onClick}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      {...draggableProvided?.dragHandleProps}
    >
      { icon ?
          <div className="study-list-group-item-icon" style={{ fontSize }}>
            <ShowIonicon iconName={icon} />
          </div> : null
      }

      <div className="ms-2 me-auto align-vertical">
        <div className="fw-bold" style={{ fontSize: fontSize * 0.7 }}>
          {title}{superscript ? (<sup>{superscript}</sup>) : null}
        </div>
      </div>
    </ListGroup.Item>
  );
});

export { StudyListGroupItem };
