import React from 'react';

import { observer } from 'mobx-react-lite';
import { Form } from 'react-bootstrap';
import { RichEditor } from '../common/RichEditor.component';

type Props = {
  value: string;
  setValue: (value: string) => void;
};

const ReminderContent: React.FC<Props> = observer(({ value, setValue }) => {
  return (
    <Form.Group className="mb-3">
      <RichEditor
        value={value}
        setValue={setValue}
      />
    </Form.Group>
  );
});

export { ReminderContent };
