import { action, makeObservable, observable } from 'mobx';
import { EditNavSelection } from '../models/EditNavSelection';
import { StudyVisit } from '../models/StudyVisit';
import { StudyStore } from './StudyStore';

export class StudyVisitChecklistStore {
  @observable
  studyScreeningVisits: StudyVisit[];

  @observable
  studyNormalVisits: StudyVisit[];

  @observable
  studyOffTreatmentVisits: StudyVisit[];

  @observable
  selectedVisit: StudyVisit | undefined;

  constructor(private studyStore: StudyStore) {
    makeObservable(this);
  }

  @action
  editVisitChecklists() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();
    const allStudyVisits = StudyVisit.createStudyVisitsFromConfig(studyProtocolConfig.visits);

    this.studyScreeningVisits = allStudyVisits.filter((visit) => visit.isScreening());
    this.studyNormalVisits = allStudyVisits.filter((visit) => !visit.isScreening());
    this.studyOffTreatmentVisits = StudyVisit.createStudyVisitsFromConfig(studyProtocolConfig.offTreatmentVisits);
    this.resetVisit();

    this.studyStore.setNavSelection(EditNavSelection.VisitChecklist);
  }

  @action
  selectVisit(selectedVisit: StudyVisit) {
    this.selectedVisit = new StudyVisit(selectedVisit.studyVisitConfig);
  }

  @action
  copyStudyVisit(studyVisitToCopy: StudyVisit) {
    if (this.selectedVisit) {
      this.selectedVisit.checklistItems = studyVisitToCopy.checklistItems.slice();
    }
  }

  @action
  applyChanges() {
    if (this.selectedVisit) {
      let studyVisits = this.studyNormalVisits;
  
      if (this.selectedVisit.isOffTreatment()) {
        studyVisits = this.studyOffTreatmentVisits;
      }

      if (this.selectedVisit.isScreening()) {
        studyVisits = this.studyScreeningVisits;
      }

      this.selectedVisit.applyChanges();
      const index = studyVisits.findIndex((studyVisit) => studyVisit.day === this.selectedVisit?.day);
      studyVisits[index] = this.selectedVisit;
      this.applyChangesToStudyConfig();
    }
  }

  @action
  resetVisit() {
    this.selectedVisit = undefined;
  }

  @action
  private applyChangesToStudyConfig() {
    const studyProtocolConfig = this.studyStore.getStudyProtocolConfig();

    studyProtocolConfig.visits = this.studyScreeningVisits.map(studyVisit => studyVisit.studyVisitConfig);
    studyProtocolConfig.visits.push(...this.studyNormalVisits.map(studyVisit => studyVisit.studyVisitConfig));
    studyProtocolConfig.offTreatmentVisits = this.studyOffTreatmentVisits.map(studyVisit => studyVisit.studyVisitConfig);
  }
}