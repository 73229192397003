import React from 'react';
import { observer } from 'mobx-react-lite';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useStores } from '../../hooks/useStores';
import { IoCogOutline } from 'react-icons/io5';
import { StudyConfig } from '../../core/StudyConfig';

type Props = {
  onNewProtocol: () => void;
  onShowProtocol: (studyConfig: StudyConfig) => void;
};

const StudyProtocolSelection: React.FC<Props> = observer(({ onNewProtocol, onShowProtocol }) => {
  const { studyStore } = useStores();

  const handleShowProtocol = (studyConfig: StudyConfig) => {
    onShowProtocol(studyConfig);
  };

  const handleNewProtocol = () => {
    onNewProtocol();
  }

  return (
    <DropdownButton variant="outline-secondary" className="margin-left" as={ButtonGroup} title={<IoCogOutline style={{fontSize: 26}} />} id="bg-nested-dropdown">
      {
        studyStore.allStudyProtocols.map((studyProtocol) => {
          return (
            <Dropdown.Item 
              key={`study-selection-${studyProtocol.studyId}`}
              className={studyProtocol.studyId === studyStore.selectedStudyConfig.studyId ? 'selected-dropdown' : ''} 
              onClick={() => handleShowProtocol(studyProtocol)}>
              {studyProtocol.protocolVersionLabel}
            </Dropdown.Item>
          );
        })
      }
      
      <Dropdown.Item onClick={handleNewProtocol}>New Protocol</Dropdown.Item>
    </DropdownButton>
  );
});

export { StudyProtocolSelection };