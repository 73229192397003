export class HtmlContent {
  static convertForEditor(htmlContent: string) {
    return htmlContent.replace(/class="alpha(.*?)"/g, 'type="a"')
      .replace(/class="roman(.*?)"/g, 'type="i"');
  }

  static convertForApp(htmlContent: string) {
    return htmlContent.replace(/type="a"/g, 'class="alpha"')
      .replace(/type="i"/g, 'class="roman"');
  }
}